//
// = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = -

console.info('go');

// = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = -
//

const $body = document.getElementsByTagName('body')[0];
const $main = document.getElementById('main');

const $colLeftRail = document.querySelector('.col.is-left-rail');
const $colMainContent = document.querySelector('.col.is-main');

const $wrapLeftRail = document.getElementById('left-rail');
const $triggerLeftRail = document.getElementById('trigger-left-rail');

// this could/should be written as vanilla js, but we're in a hurry...
const $currentNav = $('#current-manual-nav');
const $currentNavTrigger = $('#current-manual-nav > h2');

// = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = -
//

$triggerLeftRail.addEventListener('click',(event) =>{
  event.preventDefault();
  // console.info('trigger: left rail');

  $trigger = event.target;
  toggleLeftCol();
  $wrapLeftRail.classList.toggle('closed');

});

function toggleLeftCol(){
  $colMainContent.classList.toggle('js-full');
  $colLeftRail.classList.toggle('js-closed');
};


// this could/should be written as vanilla js, but we're in a hurry...
$(function(){

  // = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = -
  // handle zoom functionality for images
  const $images = $('.is-contents img').not('.no-zoom');

  $images.each(function(){
    const $img = $(this);

    $img
      .addClass('js-zoom-img')
      .wrap('<a href="'+ $img.attr('src') +'" rel="noopener" target="_blank" title="View full size image in a new tab" aria-label="click to open the '+ $img.attr('alt') +' image in a new tab" class="js-zoom-wrap"></a>');

    const $wrap = $img.parent('span');
    $wrap
      .addClass('js-zoom-wrap')
      .on('click',function(){
        window.open($img.attr('src'));
      });
  });

  // = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = -
  //


  const $currentNavChannels = $currentNav.find('> ul');

  $currentNavChannels
  .attr('id', 'current-manual-nav-channels')
  .addClass('js-current-manual-nav-channels');

  let numberSubChannels = 1;

  const $currentNavSubChannels = $currentNavChannels.find('li > ul');
  // const $currentNavSubChannelTriggers;

  $currentNavSubChannels.each(function(){
    const $subNavUl = $(this);
    const $subNavTrigger = $subNavUl.prev('a');

    $subNavUl
      .attr('id','js-current-manual-nav-sub-'+ numberSubChannels++)
      .addClass('js-current-manual-nav-sub-channels')
      .find('a')
        .attr('tabindex','-1');

    $subNavTrigger
      .attr({
        'role': 'button',
        'aria-controls': 'js-current-manual-nav-sub-'+ numberSubChannels,
        'aria-label': 'Chapters in this section',
        'aria-expanded': 'false'
      })
      .addClass('js-current-manual-nav-sub-trigger')
      .on('click',function(e){
       // e.preventDefault();

        let isOpen = $subNavTrigger.attr('aria-expanded') === 'true' ? true : false;
        if( isOpen ){
          $subNavTrigger.attr('aria-expanded','false');
          $subNavUl.removeClass('open')
          .find('a').attr('tabindex', '-1');
        } else {
          $subNavTrigger.attr('aria-expanded','true');
          $subNavUl.addClass('open')
            .find('a').removeAttr('tabindex');
        };
      });

  });

  $currentNavChannels
    .find('.current')
    .parentsUntil('.js-current-manual-nav-channels')
      .addClass('has-current-channel');

  $currentNavChannels
   .find('.current').next('ul.js-current-manual-nav-sub-channels')
        .addClass('open');
  $currentNavChannels
        .find('.current').attr('aria-expanded','true');
  $('.js-current-manual-nav-sub-channels.has-current-channel')
    .addClass('open');
  $('.js-current-manual-nav-sub-channels.has-current-channel')
    .find('a')
      .removeAttr('tabindex');


}); // end document.ready



// = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = - = -
// fin
//
